.Main {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
  box-sizing: border-box !important;
}
.Main__Container--SideNav {
  width: 18vw;
  min-width: 250px;
  height: 100%;
  max-height: 100%;
}
.Main__Container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  box-sizing: border-box;
}
.Main__Container--content {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 82vw;
  padding: 15px 20px;
  box-sizing: border-box;
}
