.CompTech--main {
  height: 100%;
  width: 100%;
}

.CompTech--main-header {
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #34495e;
}
.CompTech--main-table {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
