.NavBar {
  width: 100%;
  height: 7rem;
  background-color: var(--main-bg-color);
  z-index: 999;
  display: flex;
  border-bottom: 2px white solid;
}

.NavBar__userSec {
  position: relative;
  border: none;
  text-align: left;
  width: 18vw;
  min-width: 250px;
  height: 100%;
  box-sizing: border-box;
  border-right: 2px white solid;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  cursor: pointer;
}

.NavBar__userSec svg {
  width: 20px;
}
.NavBar__userSec--avatar {
  height: 45px;
  min-height: 45px;
  width: 45px;
  min-width: 45px;
  background-color: #f3f3f3;
  border: 3px var(--main-bg-color) solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 700;
  color: #34495e;
  margin-right: 15px;
}

.NavBar__userSec--Name {
  text-transform: uppercase;
  width: 100%;
  font-size: 1.6rem;
  color: var(--main-bg-color);
  font-weight: 500;
  text-align: left;
}

.NavBar__serchSec {
  width: 50vw;
  min-width: 600px;
  height: 100%;
  box-sizing: border-box;
}

/* DROP DOWN  */

.NavBar__userSec--dropdown--cut {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px white solid;
  top: -10px;
  right: 20px;
}
.NavBar__userSec--dropdown {
  visibility: hidden;
  position: absolute;
  /* border: 3px var(--secondary-bg-color) solid; */
  top: 7.2rem;
  left: 15%;
  width: 85%;
  background-color: var(--main-bg-color);
}
.NavBar__userSec:focus .NavBar__userSec--dropdown {
  visibility: visible;
}
.NavBar__userSec--dropdown--item {
  height: 45px;
  text-decoration: none;
  display: flex;
  align-items: center;
  border-left: 6px transparent solid;
  padding: 5px 40px 5px 20px;
  width: 100%;
  background-color: white;
  color: var(--secondary-font-color);
  font-size: 1.5rem;
  font-weight: 500;
}
.NavBar__userSec--dropdown--item:hover {
  border-left: 6px var(--secondary-bg-color) solid;
  background-color: #f1efef;
}
