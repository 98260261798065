.NotSupported {
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background-color: var(--main-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.NotSupported-container {
  width: 70%;
  height: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.NotSupported-container svg {
  width: 180px;
  height: 180px;
}

.NotSupported-container svg path {
  fill: rgb(255, 255, 255);
}
.NotSupported-container h2 {
  color: white;
  font-size: 3rem;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
