.SideNav__Main {
  position: relative;
  width: 18vw;
  min-width: 250px;
  height: 100%;
  max-height: 100%;
  background-color: #dfe6e9;
  padding-bottom: 60px;
}

.SideNav__Main--TOP {
  padding: 10px 0;
  width: 18vw;
  min-width: 250px;
  height: 195px;
  border-bottom: white 1px solid;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}

.SideNav__Main--TOP-btn {
  padding: 0 30px 0 15px;
  width: 100%;
  min-width: 250px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SideNav__Main--TOP-btn-TEXT h3 {
  color: var(--main-bg-color);
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 700;
}

.SideNav__Main--TOP-btn-TEXT p {
  padding-top: 5px;
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--secondary-bg-color);
}

.SideNav__Main h4 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: var(--secondary-bg-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4.2rem;
  font-weight: 700;
  box-sizing: border-box;
}
