.Loading {
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: var(--main-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.Loading-container {
  width: 70%;
  height: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.Loading-container svg {
  width: 150px;
  height: 150px;
  margin: 20px;
  animation: spin 2s infinite;
}

.Loading-container svg path {
  fill: rgb(255, 255, 255);
}
.Loading-container h2 {
  color: white;
  font-size: 2rem;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

@keyframes spin {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}
