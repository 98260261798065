.Register {
  width: 45vw;
  height: 100vh;
  max-height: 100vh;
  background-color: white;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}
.Register__Header {
  height: 6rem;
  margin-top: 1rem;
}
.Register__Main {
  padding: 5% 8%;
  width: 100%;
  box-sizing: border-box;
}
.Register__Main--Header {
  text-transform: uppercase;
  font-size: 4rem;
  font-family: "Roboto", sans-serif;
  padding: 1.5rem 0;
  color: var(--main-bg-color);
}

.Register__Main--text {
  color: rgb(151, 151, 151);
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.Register__Main--text span {
  background-color: #ffffff;
  cursor: default;
}

.Register__Main--text span:hover {
  background-color: var(--main-bg-color);
  color: white;
}

.Btn {
  background-color: var(--main-bg-color);
}

.Register__Form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Register__Form--err {
  color: #c0392b;
  font-size: 1.5rem;
  font-weight: 500;
  min-height: 38px;
  margin-bottom: 2rem;
  text-align: center;
  padding: 0 9%;
}
.Register__Form--success {
  color: var(--secondary-bg-color);
  font-size: 1.7rem;
  font-weight: 500;
  min-height: 38px;
  padding: 0 9%;
  text-align: center;
  margin-bottom: 2rem;
}
.Register__Form--input {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}
.Register__Main--footer {
  width: 100%;
  padding: 0 9.1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Register__Main--footer--a,
.Register__Main--footer--a a {
  font-size: 1.2rem;
  color: var(--main-bg-color);
  text-decoration: none;
  font-weight: 700;
  padding: 0 5px;
}
.Register__Main--footer--a a:hover {
  text-decoration: underline 1.2px;
}

.Register__Main--footer--a a:checked {
  color: var(--main-bg-color);
  text-decoration: none;
}
