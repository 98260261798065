.SideMenu {
  width: 55vw;
  height: 100vh;
  max-height: 100vh;
  background-color: var(--main-bg-color);
  position: relative;
  box-sizing: border-box;
  cursor: default;
  display: flex;
}
.SideMenu-main {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  padding: 6%;
  z-index: 99;
}

.SideMenu__AppName {
  color: white;
  font-size: 12rem;
  font-family: "Roboto", sans-serif;
  padding-top: 9rem;
  cursor: default;
}
.SideMenu__AppSlogon {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  max-width: 95%;
  font-size: 2.6rem;
  padding-top: 3rem;
  color: white;
  cursor: default;
}
.SideMenu__AppSlogon span {
  background-color: #ffffff;
  color: var(--main-bg-color);
  font-weight: 700;
  cursor: default;
}

.SideMenu__AppSlogon span:hover {
  background-color: var(--main-bg-color);
  color: white;
  font-weight: 700;
}

.SideMenu__FreeLogo {
  position: absolute;
  top: 70%;
  right: 10%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: white;
  font-size: 1.5rem;
  color: var(--main-bg-color);
  padding: 10px 20px;
  font-weight: 700;

  border-radius: 25px;
}

.SideMenu__FreeLogo--img {
  height: 3rem;
}

.SideMenu__AppVersion {
  position: absolute;
  bottom: 20px;
  right: 40px;
  background-color: white;
  color: var(--main-bg-color);
  font-size: 1.5rem;
  font-weight: 700;
  padding: 5px 15px;
  border-radius: 25px;
}
